import React, { useState } from 'react';

const ContactForm = () => {
    // Define state variables for form inputs
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    // TODO: Make it better
    // Responses are going here
    // https://docs.google.com/forms/d/1_jsgcydcCfZ2ibwNKIKoMwZnxZ0Xx5-OoGLWFzISpTk/edit#responses

    const handleSubmit = (e) => {
        e.preventDefault();
        const formUrl = `https://docs.google.com/forms/d/e/1FAIpQLSd9jrBRGqT6kRaYLmdLjCRPr_B7G8y_umDE05ZWxjqvLap_-w/formResponse?entry.2005620554=${name}&entry.1045781291=${email}&entry.1166974658=${phone}&entry.839337160=${message}`
        const xmlHttp = new XMLHttpRequest();
        xmlHttp.open('GET', formUrl);
        xmlHttp.send(null);
        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">
                    Name*
                </label>
                <input
                    type="text"
                    className="form-control"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">
                    Email*
                </label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="phone" className="form-label">
                    Phone Number*
                </label>
                <input
                    type="tel"
                    className="form-control"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                />
            </div>
            <div className="mb-3">
                <label htmlFor="message" className="form-label">
                    Message*
                </label>
                <textarea
                    className="form-control"
                    id="message"
                    rows="4"
                    required
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div>
            <button type="submit" className="btn btn-lg btn-outline-primary mt-3 rounded-0 px-4 py-2">
                Submit
            </button>
        </form>
    );
};

export default ContactForm;
