// Category
// 1 -> Web Pressence
// 2 -> Native Apps
// 3 -> Enterprise Dashboards

// SAMPLE
// {
//     img: 'grouppristine.webp',
//     title: 'Gupta’s Pristine Group',
//     // para: 'lorem ipsum...',
//     link: 'https://www.grouppristine.com/',
//     // android: 'url',
//     // ios: 'url',
//     // tags: ['tag1'],
//     category: 1
// },

const baseURL = "/img/projects";

const projectsData = [
    {
        img: `${baseURL}/vectorhub.webp`,
        title: 'VectorHub by Superlinked',
        link: 'https://superlinked.com/vectorhub/',
        category: 1
    },
    {
        img: `${baseURL}/casacomfort.webp`,
        title: 'Casa Comfort',
        link: 'https://www.casacomfortaus.com/',
        category: 1
    },
    {
        img: `${baseURL}/rangde.webp`,
        title: 'RangDe Blog',
        link: 'https://rangde.in/blog',
        category: 1
    },
    {
        img: `${baseURL}/grouppristine.webp`,
        title: 'Gupta’s Pristine Group',
        link: 'https://www.grouppristine.com/',
        category: 1
    },
    {
        img: `${baseURL}/mywanderlust.webp`,
        title: 'MyWanderlust.in',
        link: 'https://mywanderlust.in/',
        category: 1
    },
    {
        img: `${baseURL}/ecosaviourewaste.webp`,
        title: 'Eco Saviour',
        link: 'https://ecosaviourewaste.com/',
        category: 1
    },
    {
        img: `${baseURL}/arhamalloy.webp`,
        title: 'Arham Alloy Pvt Ltd',
        link: 'https://arhamalloy.com/',
        category: 1
    },
    {
        img: `${baseURL}/parasavnath.webp`,
        title: 'Parasavnath Trading Co.',
        link: 'https://parasavnath.com/',
        category: 1
    },
    {
        img: `${baseURL}/ynmsafety.webp`,
        title: 'YNM Safety',
        link: 'https://ynmsafety.com/',
        android: 'https://play.google.com/store/apps/details?id=com.ynm.ynmsafety',
        category: 1
    },
    {
        img: `${baseURL}/reactonie.webp`,
        title: 'Reactonite',
        link: 'https://reactonite.github.io/',
        category: 1
    },
    {
        img: `${baseURL}/dsc-iiitd.webp`,
        title: 'DSC | IIIT Delhi',
        link: 'https://avisionx.github.io/dsciiitd20/',
        category: 1
    },
    {
        img: `${baseURL}/ia-iiitd.webp`,
        title: 'International Affairs | IIIT Delhi',
        link: 'https://ia.iiitd.ac.in/',
        category: 1
    },
    {
        img: `${baseURL}/odyssey20.webp`,
        title: 'Odyssey 2020 | IIIT Delhi',
        link: 'https://odyssey20.jumpfast.tech/',
        category: 1
    },
    {
        img: `${baseURL}/esya19.webp`,
        title: 'Esya 2019 | IIIT Delhi',
        link: 'https://esyaiiitd19.jumpfast.tech/',
        category: 1
    },
    {
        img: `${baseURL}/launch-iiitd.webp`,
        title: 'LaunchIIIT 2019 | IIIT Delhi',
        link: 'https://launchiiitd20.jumpfast.tech/',
        category: 1
    },
    {
        img: `${baseURL}/delhi-corona.webp`,
        title: 'Delhi Corona',
        android: 'https://play.google.com/store/apps/details?id=com.delhi.covidcare',
        ios: 'https://apps.apple.com/in/app/delhi-corona/id1516485091',
        category: 2
    },
    // {
    //     img: `${baseURL}/amahi-app.webp`,
    //     title: 'Amahi',
    //     android: 'https://play.google.com/store/apps/details?id=org.amahi.anywhere',
    //     category: 2
    // },
    {
        img: `${baseURL}/aarabaik2.webp`,
        title: 'Araabaik',
        link: 'https://araabaik.com/',
        android: 'https://play.google.com/store/apps/details?id=com.araabaik.araabaik&pli=1',
        ios: 'https://apps.apple.com/in/app/araabaik/id6446587727',
        category: 2
    },
    {
        img: `${baseURL}/codezo.webp`,
        title: 'Codezo',
        link: 'https://codezo.in/',
        android: 'https://play.google.com/store/apps/details?id=in.codezo.codezo',
        category: 2
    },
    {
        img: `${baseURL}/creatoq.webp`,
        title: 'Creatoq',
        link: 'https://creatoq.com/',
        android: 'https://play.google.com/store/apps/details?id=com.cherrypi.creatoq',
        ios: 'https://apps.apple.com/in/app/creatoq/id6452119643',
        category: 2
    },
    {
        img: `${baseURL}/dabbagul2.webp`,
        title: 'DabbaGul',
        android: 'https://play.google.com/store/apps/details?id=com.precog.dabbagul',
        category: 2
    },
    {
        img: `${baseURL}/mime-app.webp`,
        title: 'MIME',
        android: 'https://play.google.com/store/apps/details?id=com.precog.mime',
        category: 2
    },
    // {
    //     img: `${baseURL}/nudge2.webp`,
    //     title: 'Nudge Activities',
    //     android: 'https://play.google.com/store/apps/details?id=com.nudgeverse.activities',
    //     ios: 'https://apps.apple.com/in/app/nudge-activities/id1644538345',
    //     category: 2
    // },
    {
        img: `${baseURL}/parrot2.webp`,
        title: 'Parrot',
        android: 'https://play.google.com/store/apps/details?id=com.hmilab.parrot',
        category: 2
    },
    {
        img: `${baseURL}/washkaro2.webp`,
        title: 'Washkaro - TB',
        android: 'https://play.google.com/store/apps/details?id=inspire2connect.inspire2connect',
        category: 2
    },
    {
        img: `${baseURL}/whatsviral-app.webp`,
        title: 'WhatsViral',
        android: 'https://play.google.com/store/apps/details?id=com.rutgers.whatsviral',
        category: 2
    },
    {
        img: `${baseURL}/cramsdash.webp`,
        title: 'CRAMS',
        link: 'https://crams.fh.iiitd.edu.in/',
        category: 3
    },
    {
        img: `${baseURL}/osrtcdash.webp`,
        title: 'OSRTC | Odisha Bus Ticketing',
        link: 'https://dashboard.osrtc.org/',
        android: 'https://play.google.com/store/apps/details?id=in.chartr.transit',
        category: 3
    },
    {
        img: `${baseURL}/fmsdash.webp`,
        title: 'FMS | IIIT Delhi',
        link: 'https://fms.fh.iiitd.edu.in/',
        category: 3
    },
    // {
    //     img: '/img/djb.jpg',
    //     title: 'Delhi Jal Board Helpdesk',
    //     link: 'https://drive.google.com/file/d/1dGQ1xP5V_OXqyB8nSXJDikYS5TmiMmj3/view',
    //     category: 3
    // },
    {
        img: `${baseURL}/ashadash.webp`,
        title: 'Asha Education | Hero Foundation',
        android: 'https://play.google.com/store/apps/details?id=com.rkmf.ashacenter',
        link: 'https://asha.ramanmunjalvidyamandir.org/',
        category: 3
    },
    {
        img: `${baseURL}/dhandash.webp`,
        title: 'Dhancharcha | Hero Foundation',
        android: 'https://play.google.com/store/apps/details?id=com.rkmf.dhancharcha',
        link: 'https://dhancharcha.ramanmunjalvidyamandir.org/',
        category: 3
    },
    // {
    //     img: '/img/sg-cw-iiitd.webp',
    //     title: 'SG/CW | IIIT Delhi',
    //     link: 'http://sgcw.iiitd.edu.in/student/home/',
    //     category: 3
    // },
    {
        img: `${baseURL}/saatdash.webp`,
        title: 'Saat Seedhi | Tifo Learning Solutions',
        android: 'https://play.google.com/store/apps/details?id=com.tifo.saatseedhi',
        link: 'https://saatseedhi.tifoindia.org/login/?next=/',
        category: 3
    },
    // {
    //     img: '/img/ynm-sales.webp',
    //     title: 'YNM Safety Sales',
    //     android: 'https://play.google.com/store/apps/details?id=com.ynm.sales',
    //     link: 'https://sales.ynmsafety.com/',
    //     category: 3
    // },
]
export default projectsData;